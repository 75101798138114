import { Link } from "react-router-dom";
import QuantityBox from "../components/QuantityBox";
import { IoIosClose } from "react-icons/io";
import Button from '@mui/material/Button';
import { IoBagCheckOutline } from "react-icons/io5";
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../App";
import ProductoDataService from "../services/product.service";
import { FormatoNumero } from "../utils/Utility";
const Cart = () => {

    let [cartFields, setCartFields] = useState({});
    const [cartData, setCartData] = useState([]);
    const [productQuantity, setProductQuantity] = useState();
    const [chengeQuantity, setchengeQuantity] = useState(0);

    const context = useContext(MyContext);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                let storedCart = JSON.parse(localStorage.getItem('cart')) || [];
                let updatedCart = [];

                // Fetch the product details for each item in the cart
                for (let item of storedCart) {
                    try {
                        const response = await ProductoDataService.getById(item.pro_id);
                        const productData = response.data;

                        let subtotal = parseFloat(item?.price * item?.quantity)

                        updatedCart.push({
                            ...item,
                            price: productData?.prs_precio, pro_descuento: productData?.pro_descuento, subTotal: parseFloat(subtotal - (subtotal * item?.pro_descuento / 100))
                        });
                    } catch (error) {
                        console.error(`Failed to fetch data for product ID: ${item.pro_id}`, error);
                    }
                }

                // Update localStorage and cart state with updated cart data
                localStorage.setItem('cart', JSON.stringify(updatedCart));
                setCartData(updatedCart);
            } catch (e) {
                console.error("Failed to load or update cart data.", e);
            }
        };

        fetchProductData();

    }, []);

    const quantity = (val) => {
        setProductQuantity(val);
        setchengeQuantity(val);
    };



    //ACTUALIZANDO CANTIDAD DE PRODUCTOS DEL CARRO
    const selectedItem = (item, quantityVal) => {
        if (chengeQuantity !== 0) {

            cartFields.pro_id = item?.pro_id;
            cartFields.pro_nombre = item?.pro_nombre;
            cartFields.price = item?.price;
            cartFields.quantity = quantityVal;
            let subtotal = parseFloat(item?.price * quantityVal)
            cartFields.subTotal = parseFloat(subtotal - (subtotal * item?.pro_descuento / 100));
            cartFields.countinstock = item?.countinstock;
            //ACTUALIZAR EN EL LOCAL
            let storedCart = JSON.parse(localStorage.getItem('cart')) || [];
            const index = storedCart.findIndex((cartItem) => cartItem.pro_id === cartFields.pro_id);
            if (index !== -1) {
                storedCart[index].quantity = cartFields.quantity
                storedCart[index].subTotal = cartFields.subTotal;
                localStorage.setItem('cart', JSON.stringify(storedCart));
                setCartData(JSON.parse(localStorage.getItem('cart')) || []);
            }
        }
    }

    const removeItem = (id) => {
        //setIsLoading(true);
        let storedCart = JSON.parse(localStorage.getItem('cart')) || [];

        storedCart = storedCart.filter((cartItem) => cartItem.pro_id !== id);

        localStorage.setItem('cart', JSON.stringify(storedCart));
        setCartData(JSON.parse(localStorage.getItem('cart')) || []);
        context.getCartData();
        context.setAlertBox({
            open: true,
            error: false,
            msg: "Ítem eliminado del carro!"
        });
    }

    return (
        <>
            <section className="section cartPage">
                <div className="container">
                    <h2 className="hd mb-1">Carrito</h2>
                    {cartData && cartData?.length > 0 ? (
                    <div className="row">
                        <div className="col-md-9 pr-5">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th width="35%">Producto</th>
                                            <th width="15%">Precio</th>
                                            <th width="25%">Cantidad</th>
                                            <th width="15%">Subtotal</th>
                                            <th width="10%">Eliminar</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            cartData?.length !== 0 && cartData?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td width="35%">
                                                            <Link to={`/product/${item?.pro_id}`}>
                                                                <div className="d-flex align-items-center cartItemimgWrapper">
                                                                    <div className="imgWrapper">
                                                                        <img className="w-100" src={ProductoDataService.getPathImage(encodeURIComponent(item?.image?.ima_direccion))} alt={item?.pro_nombre} />
                                                                    </div>

                                                                    <div className="info px-3">
                                                                        <h6>{item?.pro_nombre?.substr(0, 30) + '...'}</h6>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td width="15%">${FormatoNumero(item?.price - (item?.price * (item?.pro_descuento / 100)))}</td>
                                                        <td width="25%">
                                                            <QuantityBox quantity={quantity} item={item} selectedItem={selectedItem} value={item?.quantity} />
                                                        </td>
                                                        <td width="15%">${FormatoNumero(item?.subTotal)}</td>
                                                        <td width="10%"><span className="remove" onClick={() => removeItem(item?.pro_id)}><IoIosClose /></span></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card border p-3 cartDetails">
                                <h4>Total carrito</h4>
                                <div className="d-flex align-items-center mb-3">
                                    <span>Subtotal</span>
                                    <span className="ml-auto text-orange font-weight-bold">$
                                        {
                                            FormatoNumero((cartData?.length !== 0 ? cartData.map(item => parseFloat((item.price * item.quantity) - ((item.price * item.quantity) * item.pro_descuento / 100))).reduce((total, value) => total + value, 0) : 0))
                                        }</span>
                                </div>
                                {/*<div className="d-flex align-items-center mb-3">
                                    <span>Envío</span>
                                    <span className="ml-auto"><b>Gratis</b></span>
                                </div>
                                <div className="d-flex align-items-center mb-3">
                                    <span>Estimado para</span>
                                    <span className="ml-auto"><b>Santo Domingo de los Tsáchilas</b></span>
                                </div>*/}
                                <div className="d-flex align-items-center mb-3">
                                    <span>Total</span>
                                    <span className="ml-auto text-orange font-weight-bold">${
                                            FormatoNumero((cartData?.length !== 0 ? cartData.map(item => parseFloat((item.price * item.quantity) - ((item.price * item.quantity) * item.pro_descuento / 100))).reduce((total, value) => total + value, 0) : 0))
                                        }</span>
                                </div>
                                <br />
                                {/*<Button className="btn-blue btn-lg btn-big" onClick={() => saveOrder()}><IoBagCheckOutline />&nbsp;Checkout</Button>*/}
                                <Link to="/checkout" disabled={false}>
                                    <Button  className="btn-blue btn-lg btn-big" disabled={false} fullWidth><IoBagCheckOutline />&nbsp;Checkout</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    ):(
                        <>
                        <h4>Su carrito está vacio</h4>
                        <p>Para seguir comprando, navegar por las categorías en el sitio, o busque su producto.</p>
                        </>
                    )}
                </div>
            </section>
        </>
    )
}

export default Cart;